import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  VideoAndText,
  BannerLinks,
  BannerSmall,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.tq.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.rauchmehl.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.stadtamthall.png'

const TqSytemes = () => {
  const links = generateBreadcrumbsLinks('/projekte/relaunch-tq-group', 'Relaunch TQ-Group')
  return (
    <Layout margin={false} color="black">
      <Helmet
        title="Die neue TQ Group Website - out of reach"
        meta={[
          {
            name: 'description',
            content:
              'Die TQ Groupaus dem Bayerischen Seefeld, welcher sich mit der Entwicklung und Herstellung von Mikrocontroller-Modulen über Antriebslösungen für E-Bikes bis hin zu Medizintechnischen-Produkten beschäftigt, glaubt daran: Image - you can do it!'
          },
          {
            name: 'keywords',
            content:
              'TQ holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, TQ-Group, TQ-Systems, TQ Group, E-Bikes, Mikrocontroller, TQ Systems, Personas, eZ-Platform, ezplatform'
          },
          {
            property: 'og:description',
            content:
              'Die TQ Groupaus dem Bayerischen Seefeld, welcher sich mit der Entwicklung und Herstellung von Mikrocontroller-Modulen über Antriebslösungen für E-Bikes bis hin zu Medizintechnischen-Produkten beschäftigt, glaubt daran: Image - you can do it!'
          },
          {
            property: 'og:image',
            content: 'https://i.ytimg.com/vi/AJDh2SFetAI/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGBQgYCh_MA8=&rs=AOn4CLANE9l6j0X-gEFijFeV_jx9ePeHlw'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Träumen. Bauen. Machen." />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Die neue TQ Group Website <br /> - out of reach!
          </div>
        }
        text={
          <span>
            <p>Die TQ Group aus dem Bayerischen Seefeld, welcher sich mit der Entwicklung und Herstellung von Mikrocontroller-Modulen über Antriebslösungen für E-Bikes bis hin zu Medizintechnischen-Produkten beschäftigt, glaubt daran: es können immer bessere Wege gefunden, Grenzen umgangen und Lösungen an der richtigen Stelle gefunden werden! Oder auch kurz: <b><i>Imagine - you can do it!</i></b><br/><br/>Den Optimismus durften wir auch in der Zusammenarbeit mit TQ-Systems bis 2019 erfahren und uns davon mitreißen lassen. Neben dem Rebranding der Marke TQ, welches das Unternehmen mit einer deutschen Agentur bereits gestartet hatte, wurden wir mit dem Redesign der Website beauftragt. So modern und innovativ wie seine Produkte sollte natürlich auch das digitale Aushängeschild des deutschen Vorwärtsstürmers sein.</p>
          </span>
        }
      />
      <VideoAndText
        YTvideo="AJDh2SFetAI"
        headline="Präsentationsvideo"
        text="Für die TQ-Weihnachtsfeier haben wir ein lässiges Video zusammengestellt, um den Mitarbeitern von TQ einen ersten Einblick von den neuen Webportalen zu geben. Sound on!"
      />
      <ProjectPagination
        titleLeft={
          <span>Rauchmehl Website<br/>im neuen Gewand</span>
        }
        imageLeft={image4}
        leftLink="/projekte/rauchmehl"
        titleModsLeft={["black"]}
        titleRight="Stadtamt Hall Relaunch"
        imageRight={image5}
        rightLink="/projekte/stadtamthall"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default TqSytemes
